@media (max-width: 992px) {
    #myVideo {
        position: fixed;
        right: 0px;
        bottom: 0px;
        width: 100vw;
        height: auto;
        z-index: -1;
    }
}

@media (min-width: 992px) and (max-width: 1300px) {
    #myVideo {
        position: fixed;
        right: 0px;
        bottom: 0px;
        width: 100vw;
        height: auto;
        z-index: -1;
    }

    .product-card {
        min-height: 300px;
    }
}

@media (max-width: 576px) {
    #myVideo {
        position: fixed;
        /* right: 0px;
       
        bottom: 0px; 
        width: 100vw;
        height: 100%;  */
        z-index: -1;
        top: 0;
        /* left: 0; */
    }
}

@media (min-width: 300px) and (max-width: 480px) {
    .category_name {
        font-size: 13px;
        font-weight: 600 !important;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .product-name {
        font-size: 15px;
    }

    .header-container {
        padding: 20px 0;
    }

    .header-container .header-title {
        font-size: 20px;
    }
}

@media (min-width: 300px) and (max-width: 380px) {
    .addInstructions span {
        font-size: 9px;
    }

    .addInstructions svg {
        color: #fff;
    }

}

@media only screen and (min-width:200px) and (max-width:567px) {
    .add-deserts-box h5 {
        font-size: 14px;
        text-transform: uppercase;
    }

    .add-deserts-box p {
        font-size: 12px;
        margin-bottom: 5px;
        letter-spacing: 1px;
    }
}

@media only screen and (min-width:568px) and (max-width:992px) {
    .select-category-main-box {
        /* max-height: 100vh; */
        /* min-height: 40vh; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-container-container {
        background-color: rgba(0, 0, 0, 0.7);
        height: 110px;
        position: relative;
        padding: 10px 0px;
    }

    .product-card {
        width: 66vw;
        max-width: 100vw;
        min-height: 345px;
        position: relative;
        border-radius: 0px;
        box-sizing: border-box !important;
        overflow: hidden;
    }

    .product-card-bottom-part .cart {
        border: 1px solid white;
        padding: 12px;
        font-size: 12px;
        border-radius: 5px;
        width: 130px;
        letter-spacing: 1px;
        max-width: 100%;
    }

    .modal-dialog-centered {
        display: flex;
        align-items: center;
        min-height: 600px;
    }

    .add-to-cart-box .add-to-cart {
        background-color: white;
        color: black;
        border: 1px solid white;
        padding: 6px 10px;
        border-radius: 5px;
        font-size: 16px;
        display: flex;
        font-weight: 700;
        justify-content: space-evenly;
        letter-spacing: 1px;
    }

    .btnclose {
        background-color: #fff !important;
        padding: 10px 15px;
        color: #000 !important;
        width: 45%;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;
    }

    .modal-footer {
        display: flex;
        flex-shrink: 0;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    .cart-product-name {
        color: white;
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .cart-Product {
        padding: 30px;
    }

    .space-between {
        justify-content: space-between;
        display: flex;
        align-items: center;
        border: 1px solid white;
        width: 130px;
        border-radius: 5px;
        overflow: hidden;
        padding: 10px 8px 10px 12px;
        right: 20px;
        position: absolute;
        top: 8%;
    }

    .pl-20 {
        /* padding-left: 20px; */
        padding-left: 260px;
    }

    .mobile-reg-box {
        position: absolute;
        z-index: 9;
        text-align: center;
        top: 20%;
        left: 0;
        width: 50vw;
        right: 0;
        margin: 0 auto;
    }

    .otp-confirm-msg {
        color: white;
        font-size: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
        width: 50%;
        margin: 0 auto;
        text-align: center;
        margin-top: 20px;
        line-height: 30px;
    }

}

@media only screen and (min-width:993px) and (max-width:1024px) {

    .product-card {
        width: 40vw;
        max-width: 100vw;
        min-height: 345px;
        position: relative;
        border-radius: 0px;
        box-sizing: border-box !important;
        overflow: hidden;
        margin-top: 20px;
    }

    .cart-Product {
        padding: 30px;
    }

    .space-between {
        justify-content: space-between;
        display: flex;
        align-items: center;
        border: 1px solid white;
        width: 180px;
        min-height: 55px;
        border-radius: 5px;
        overflow: hidden;
        padding: 12px 8px 10px 12px;
    }

    .modal-white-button {
        background-color: white;
        color: black;
        border-radius: 5px;
        margin: 10px 0px;
        padding: 15px 0px;
        outline: none;
        border: none;
        width: 35%;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1px;
    }

    .outline-button {
        border: solid 1px white;
        background-color: transparent;
        color: white;
        border-radius: 5px;
        margin: 10px 0px;
        padding: 15px 0px;
        width: 35%;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .d-f-s-b {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .pl-20 {
        /* padding-left: 20px; */
        padding-left: 260px;
    }

    .mobile-no-ip input {
        background-color: transparent;
        border-radius: 2px;
        min-height: 70px;
    }

    .otp-head {
        color: white;
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: 4px;
        margin-bottom: 20px;
    }

    .mobile-reg-box {
        position: absolute;
        z-index: 9;
        text-align: center;
        top: 0%;
        left: 0;
        width: 100vw;
        right: 0;
        margin: 0 auto;
    }

    .otp-bottom-info a {
        color: #FF6F5B;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 24px;
        padding: 10px;
    }

    .logo-img img {
        height: 100px;
        width: 100px;
    }

    .otp-confirm-msg {
        color: white;
        font-size: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
        width: 65%;
        margin: 0 auto;
        text-align: center;
        margin-top: 20px;
        line-height: 30px;
    }
}