* {
  font-family: 'DIN Next LT Pro';
}

li {
  list-style: none;
}

body {
  padding-bottom: 80px;
}

#myVideo {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  right: 0px !important;
  bottom: 0px !important;
  width: 100% !important;
  height: 100% !important;
  /* max-width: 100vw !important; */
  object-fit: cover;
  /* max-height: 100vh !important; */
  z-index: -3 !important;
  /* overflow: hidden; */
  /* background-color: red; */
}

/* #myVideo::before{
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: red;
  z-index: 9999999999999;
} */

.searchInputLightMode {
  padding: 10px;
  width: 90vw;
  /* margin-left: 50%;
  transform: translateX(-50%); */
  margin-top: 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: transparent;
  border: rgb(194, 194, 194) solid 1px;
  color: black;
}

.searchInputDarkMode {
  padding: 10px;
  width: 90vw;
  /* margin-left: 50%;
  transform: translateX(-50%); */
  margin-top: 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: transparent;
  border: rgb(194, 194, 194) solid 1px;
  color: white;
}

.searchInputLightMode::placeholder {
  color: black
}

.searchInputDarkMode::placeholder {
  color: white
}

.header-container-container {
  background-color: rgba(0, 0, 0, 1);
  height: 110px;
  position: relative;
  /* display: flex; */
  /* flex: 1; */
  /* flex-direction: row; */
}

.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.search-button {
  position: relative;
}

.search-box {
  position: absolute;
  top: calc(5vh + 5px);
  right: 10px;
  padding: 5px;
  border-radius: 5px;
  z-index: 1500;
}

.header-container svg {
  width: 50px;
  width: 50px;
  color: #fcfcfc;
}

.header-title {
  /* height: 5vh; */
  position: absolute;
  top: 22px !important;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  /* display: grid; */
  color: white;
  /* flex: 1; */
  /* top: 0px; */
  /* font-size: 28px; */
  place-items: center;
  height: 25px;
  width: 60% !important;
  text-align: center;
}

.logo-container {
  display: grid;
  place-items: center;
  margin-top: 8vh;
  margin-bottom: 12vh;
}

.main-logo {
  height: 100px;
  /* width:; */
}

.menu-btn-img {
  background-color: rgba(0, 0, 0, 0);
  border: rgb(194, 194, 194) solid 1px;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}


.menu-btn .menu-btn-img img {
  width: 50%;
}

.category-name {
  text-align: center;
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  /* padding: 5px; */
  letter-spacing: 1px;
  margin-top: 5px;
  font-weight: 300 !important;
}

.category_name {
  color: rgb(194, 194, 194);
}

.menu-btn1 {
  background-color: rgb(241, 236, 236);
  border-radius: 10px;
  padding: 21px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin: 10px auto;
}

.check-pincode {
  position: relative;
}

.txt-input {
  border: solid 1px rgb(194, 194, 194);
  border-radius: 5px;
  width: 90%;
  background-color: transparent;
  padding: 10px 10px;
  color: white;
  outline: none;
}

.txt-input::-webkit-input-placeholder,
.txt-input::placeholder {
  outline: none;
  color: white;
}

.white-button {
  position: absolute;
  width: 33%;
  background-color: white;
  color: black;
  border-radius: 0px;
  margin: 10px 0px;
  padding: 10px 0px;
  outline: none;
  border: none;
  right: 20px;
  bottom: -9px;
}

.product-card {
  width: 100vw;
  max-width: 100vw;
  min-height: 24.3vh;
  position: relative;
  border-radius: 0px;
  box-sizing: border-box !important;
  overflow: hidden;
}

/* .product-cardbill {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 200px;
  flex: 1 1 0%;
  height: unset;
} */

.product-cardbill-white-item-text {

  font-size: 14px;
  margin-top: 10px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;

}

.Video-Image-overlay {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 1) 30%);
  position: absolute;
  left: 0px;
  min-height: 20%;
  width: 100%;
  bottom: 0;
}

.overlay {

  /* background-color: rgba(0, 0, 0, 0.849) !important; */
  /* background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 21.88%, rgba(0, 0, 0, 1) 100%); */
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 1) 100%);
  position: absolute;
  left: 0px;
  min-height: 100%;
  width: 100%;
  top: 0;
}

.products-card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.product-name {
  font-weight: 400;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-left: 0px;
  margin-top: 21px;
  display: flex;
}

.d-f-fr {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  align-items: center;
}

.product-name h5 {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
}

.product-name span {
  display: flex;
}

.product-name .heading {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  display: flex;
}

.product-name p {
  color: white;
  margin-bottom: 8px;
}

.cart-price {
  font-size: 13px;
  font-weight: 100 !important;
  color: #B1B1B1;
  margin-bottom: 10px;
}

.cart-price2 {
  font-size: 13px;
  font-weight: 300 !important;
  color: #B1B1B1;
  display: flex;
  justify-content: end;
  letter-spacing: 1px;
}

.desert-name {
  color: #B1B1B1;
  font-size: 11px;
}

.product-price {
  display: inline-block;
  font-size: 12px;
  font-weight: 100 !important;
  color: white;
}


.product-card-bottom-part img {
  color: white;
  height: 40px;
}


.cart-Product {
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 10px 10px 10px;
  /* margin-bottom: 10px; */
  max-width: 100%;
}

.cart-Product1 {
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  background-color: #1a1b1ed1;
  margin: 0 auto;
  border-radius: 5px;
  margin-top: 20px;
  padding: 20px;
}

.cart-Product2 {
  width: 100vw;
  min-height: 75px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  max-width: 100%;
  border-bottom: 1px solid #6a6a6a;
}

.d-f-j-c-e {
  display: flex;
  justify-content: end;
}


.d-f-fd-r {
  display: flex;
  align-items: center;
  padding-left: 0px;
  margin-bottom: 0px;
  justify-content: space-between;
  position: relative;
  padding-bottom: 10px;
}


.cart-product-name {
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
}

.bottom-part {
  padding-left: 0px;
}

.bottom-part li {
  line-height: 25px;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: capitalize;
  font-family: sans-serif;
}

.bottom-part .cursor-pointer {
  text-decoration: underline;
  font-size: 14px;
  text-transform: capitalize;
}

.space-between {
  justify-content: space-between;
  display: flex;
  align-items: center;
  border: 1px solid rgb(194, 194, 194);
  border-radius: 5px;
  overflow: hidden;
  padding: 10px 8px 10px 12px;

}

.addInstructions {
  max-width: 100%;
  background-color: #151312;
  position: fixed;
  bottom: 0;
  text-align: center;
  padding: 10px;

}

.addInstructions svg {
  color: #fff;
}

.bottom_fixed {
  position: fixed;
  width: 100%;
  background-color: #151312;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  padding: 10px 10px;
}

.bottom_fixed p,
.bottom_fixed h4,
.bottom_fixed .cursor-pointer,
.rightbootm {
  color: #fff;
  margin-bottom: 0;
}

.bottom_fixed h4 {
  font-size: 13px;
  /* margin-top: 5px; */
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 300 !important;
}


.bottom_text {
  position: fixed;
  width: 100%;
  /* background-color: #00000015 !important; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  bottom: 0;
  z-index: 100000 !important;
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.9);
  left: 0;
}

.bottom_text p,
.bottom_text h4,
.bottom_text .cursor-pointer,
.rightbootm {
  /* color: #fff; */
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 300 !important;
}

.proceed-btnn {
  background-color: white;
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px;
  border-radius: 5px;
  letter-spacing: 1px;
}

.customer-name {
  font-size: 14px;
}

.bottom_text h4 {
  font-size: 15px;
  margin-top: 5px;
}

.addInstructions span {
  color: white;
  font-size: 14px;
}

.modalHeading {
  /* color: white; */
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 18px;
  margin-bottom: 10px;
}

.modal-white-button {
  background-color: white;
  color: black;
  border-radius: 5px;
  margin: 10px 0px;
  padding: 10px 0px;
  outline: none;
  border: none;
  width: 45%;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}

.outline-button {
  border: solid 1px white;
  background-color: transparent;
  color: white;
  border-radius: 5px;
  margin: 10px 0px;
  padding: 10px 0px;
  width: 45%;
  /* font-weight: 600; */
  text-transform: uppercase;
  letter-spacing: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.initial-screen-contianer {
  min-height: 100vh;
  display: grid;
  place-items: center;
  width: 100vw;
  background-color: black;
}

.fixed_butttonard {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 700;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 14px;
  background: rgb(0, 0, 0, 0.6);
}

.filter-header h3 {
  margin-bottom: 0;
  color: #fff;
}

.filter-header svg {
  color: #fff;
}

.filter-header .flteriocn {
  font-size: 30px;
}

.filter-card h5 {
  font-size: 12px;
  text-align: center;
  color: white;
  text-transform: uppercase;
  line-height: 25px;
  margin-top: 5px;
  font-weight: 100 !important;
  letter-spacing: 1px;
}

.filter_card {
  padding-left: 20px;
  margin-bottom: 10px;
  padding-top: 15px;
}

.filter_card h5 {
  margin-bottom: 0;
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
  font-weight: 400 !important;
  font-weight: 300 !important;
}

.filter-ip {
  background-color: transparent;
  width: 16px;
  height: 16px;
  border-radius: 2px;
}

.filter_card img {
  width: 27px;
}

.iconborder.active {
  border: #e2e2e2 solid 2px;
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}

.filter_card.active h5 {
  color: #fff;
}

.iconborder {
  border: 1px solid #e2e2e2;
  padding: 10px;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconborder img {

  width: 50%;
}

.buttn-filter {
  width: 95%;
  /* min-height: 48px; */
  border: 1px solid #fff !important;
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
  text-decoration: none;
  background: white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  justify-content: center;
  letter-spacing: 1px;
  border-radius: 5px;
}

.buttn-clear {
  width: 95%;
  /* min-height: 50px; */
  border: 1px solid #000 !important;
  padding: 12px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  text-decoration: none;
  background: black;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  justify-content: center;
  letter-spacing: 1px;
  border-radius: 5px;
}


.porduct_section img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.porduct_section video {
  width: 100%;
  min-height: 225px;
  position: relative;
}

.product_deaisl_content {
  padding: 20px 0;
  color: #fff;
  /* height: 250px; */
  border-top: 1px solid #333333;
}

.datails_area h4 {
  position: relative;
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  /* text-decoration: underline; */
  font-weight: 300 !important;
  letter-spacing: 1px;
}

.datails_area h4 span {
  width: 10px;
  height: 10px;
  max-width: 10px;
  max-height: 10px;
  min-width: 10px;
  min-height: 10px;
  /* background-color: red; */
  border-radius: 30px;
  display: block
}

/* .price_info {
  display: flex;
  justify-content: space-between;
} */

.price_info h4 span {
  font-size: 19px;
  padding-left: 7px;
}

.border-bottom1 {
  border-bottom: 1px solid #fff;
  opacity: 0.2;
}

/* .pading_tpbtm {
  padding: 10px 0;
} */

.product-cardbill-white-item {
  color: white;
  border: rgb(194, 194, 194) solid 1px;
  border-radius: 50%;
  font-weight: 500;
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  overflow: hidden;
  margin: 0 auto;
}

.product-cardbill-white-item img {
  width: 50%;
}

.center-text-white img {
  height: 60px;
}

.feature_list ul,
.feature_list_light ul {
  padding: 0;
  margin: 0;
}

.feature_list li,
.feature_list_light li {
  position: relative;
  padding-left: 15px;
  margin-bottom: 10px;
  font-size: 14px;
  text-transform: capitalize !important;
  color: #B1B1B1;
  letter-spacing: 1px;
}

.feature_list li::before {
  position: absolute;
  content: '';
  left: 0;
  top: 8px;
  border-radius: 22px;
  width: 5px;
  height: 5px;
  background-color: #fff;
}

.feature_list_light ul li::before {
  position: absolute;
  content: '';
  left: 0;
  top: 8px;
  border-radius: 22px;
  width: 5px;
  height: 5px;
  background-color: #000;
}





.btnclose1 {
  background-color: #000;
  padding: 7px 15px;
  color: #fff;
}

.prod-selectaddons .modal-footer {
  justify-content: space-evenly;
}

.btnclose {
  /* background-color: #fff !important; */
  /* padding: 7px 15px; */
  /* color: #000 !important; */
  width: 36%;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  min-height: 30px;
  z-index: 9999999;
}

.modelvaraition .modal-content {
  background-color: #000;
  color: #fff;
}

.modelvaraitionLight .modal-content {
  background-color: #fff;
  color: #000;
}

.borderraduis {
  display: block;
  width: 20px;
  height: 20px;
  /* border: 2px solid #ccc; */
  text-align: center;
  color: #fff;
  /* border-radius: 15px; */
  line-height: 20px;
}

.borderraduis svg {
  font-size: 14px;
}

.price_section h4 {
  font-size: 14px;
}

.price_section h4 span {
  font-size: 11px;
}

.price_section {
  text-align: end;
}

.videooverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-color: rgb(0 0 0 / 20%);
  /* background-color: rgb(0 0 0 / 4%); */
}

.svgicon svg {
  font-size: 25px;
}

.list_tab ul {
  padding: 0;
  display: flex;
  justify-content: space-between;

}

.list_tab ul li {
  flex: 1;
  text-align: center;

}

.tabs.productwith {
  padding: 0;
  margin: 0;
  gap: 6px;
  display: flex;
  justify-content: space-between;
}

.tabs.productwith li {
  flex: 1;
  text-align: center;
  padding-bottom: 10px;
}

.tabs.productwith li.active {
  border-bottom: 1px solid #fff;

}

.tab-inner {
  margin-top: 20px;
}

.tab-inner ul {
  padding: 0;
  margin: 0;
}

.tab-inner li {
  margin-top: 5px;
  padding-left: 15px;
  position: relative;
}

.tab-inner li::after {
  position: absolute;
  left: 0;
  content: '';
  top: 8px;
  background-color: #fff;
  width: 5px;
  height: 5px;
  border-radius: 40px;
}

.or_section {
  /* display: block;
  width: 60px;
  height: 60px;
  background-color: rgba(0,0,0,0.5);
  border-radius: 50px; */
  /* line-height: 60px; */
  margin: auto;
  color: #fff;
  font-weight: 500;
  font-size: 40px;
}

.d-flexbetween {
  display: flex;
  justify-content: end;
  gap: 15px;
}

.nuubarcart {
  position: absolute;
  top: 0px;
  right: 6px;
  background-color: #fff;
  padding: 0px;
  border-radius: 20px;
  font-size: 10px;
  width: 15px;
  height: 15px;
  text-align: center;


}

.nuubarcart1 {
  position: absolute;
  top: 5px;
  right: 9px;
  background-color: #fff;
  padding: 0px;
  border-radius: 20px;
  font-size: 10px;
  width: 15px;
  height: 15px;
  text-align: center;


}

.flexe1page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #000000; */
  flex: 1;
  flex-direction: column;
  height: 83vh;
}

.flexe1page h2 {
  font-size: 26px;
  margin-top: 15px;
  color: #fff;
  position: absolute;
  top: 15%;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.flexe1page .brandLogo {
  width: 33%;
  position: absolute;
  bottom: 130px;
}

.flexe1page .restaurantLogo {
  width: 35%;
  position: absolute;
  bottom: 50%;
}

.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.one2 {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.9s;
}

.one3 {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 1.9s;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.menu_datils {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  border-radius: 10px;
  margin-top: 40px;
  color: #fff;
}

.leftsection {
  text-align: right;
}

.menu_datils table tr {
  color: #fff;
}

.menu_datils tr {
  border-bottom: 1px solid #d1d1d173;

}

.leftsection td {
  padding: 0;
}

.menu_datils table tr th {
  padding: 15px 0;
}

.menu_datils table tr td {
  padding: 15px 0;
}

.menu_datils table tr td span {
  padding: 0;
}

.menu_datils table tr td span svg {
  font-size: 14px;
  padding: 0;
}

.header-title img {
  width: 30px;
}


/* Invoice Css Start */
.text-secondary-d1 {
  color: #728299 !important;
}

.page-header {
  margin: 0 0 1rem;
  padding-bottom: 1rem;
  padding-top: .5rem;
  border-bottom: 1px dotted #e2e2e2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}

.page-title {
  padding: 0;
  margin: 0;
  font-size: 1.75rem;
  font-weight: 300;
}

.brc-default-l1 {
  border-color: #dce9f0 !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -.25rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

.text-grey-m2 {
  color: #888a8d !important;
}

.text-success-m2 {
  color: #86bd68 !important;
}

.font-bolder,
.text-600 {
  font-weight: 600 !important;
}

.text-110 {
  font-size: 110% !important;
}

.text-blue {
  color: #478fcc !important;
}

.pb-25,
.py-25 {
  padding-bottom: .75rem !important;
}

.pt-25,
.py-25 {
  padding-top: .75rem !important;
}

.bgc-default-tp1 {
  background-color: rgba(121, 169, 197, .92) !important;
}

.bgc-default-l4,
.bgc-h-default-l4:hover {
  background-color: #f3f8fa !important;
}

.page-header .page-tools {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.btn-light {
  color: #757984;
  background-color: #f5f6f9;
  border-color: #dddfe4;
}

.w-2 {
  width: 1rem;
}

.text-120 {
  font-size: 120% !important;
}

.text-primary-m1 {
  color: #4087d4 !important;
}

.text-danger-m1 {
  color: #dd4949 !important;
}

.text-blue-m2 {
  color: #68a3d5 !important;
}

.text-150 {
  font-size: 150% !important;
}

.text-60 {
  font-size: 60% !important;
}

.text-grey-m1 {
  color: #7b7d81 !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.filter_item {
  display: grid;
  place-items: center;
  /* background-color: red; */
}

.filterContainer {
  padding-top: 50px;
  padding-bottom: 20px;
  /* height: calc(85vh - 100px) !important; */
  /* background-color: red; */
  /* display: grid;
  place-items: center; */
  /* display: "flex"; justifyContent: "center"; alignItems: "center", minHeight: "95vh" */
}

.filter_item .filter-card {
  margin-bottom: 20px;
}

.allergenItems {
  /* border-bottom: solid 1px white; */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
}

.allergenItems img {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: solid 1px white;
}

.css-zun73v.Mui-checked {
  color: white !important;
}

.css-zun73v {
  color: white !important;
}


.allergenItems span {
  color: white !important;
  margin-left: 5px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.activeAllergen img {
  border: solid 4px white;
  /* border-radius: 10px; */
  /* margin: 10px 5px; */

}

/* Invoice Css End */

/* product-card-bottom-card by mukul */

.product-card-bottom-part .cart img {
  display: none;
}

.product-card-bottom-part .cart {
  /* border: 1px solid rgb(194, 194, 194); */
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
  max-width: 120px;
  min-width: 100px;
  letter-spacing: 1px;
  /* max-width: 100%; */
}

.product-card-bottom-part .cart .add-to-cart {
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;

}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: white !important;
  background-color: transparent !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {

  color: white !important;
}

.apply-clear-btns {
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
}

.pl-20 {
  padding-left: 20px;
}

.d-f-j-c-s-b {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-pricee {
  padding-left: 20px;
  font-size: 14px;
  margin-bottom: 0px;
}

.description-list {
  padding-top: 15px;
  padding-left: 20px;
  margin-bottom: 15px;
}

.description-list li p {
  margin-bottom: 5px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 100 !important;
  color: #B1B1B1;
}

.description-list li p span {
  color: white;
  font-size: 14px;
  font-weight: 100 !important;
  letter-spacing: 1px;
}

.add-to-bag {
  display: flex;
  justify-content: space-around;
  /* width: 80%; */
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 1px;

}

.add-to-bag-pd {
  display: flex;
  justify-content: space-around;
  width: 25%;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 1px;
  z-index: 9999;
  position: absolute;
  right: 2%;
  color: white;
  bottom: 5%;
}

.do {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 100 !important;
  letter-spacing: 1px;
}

.add-to-bag .picon {
  color: black;
  font-size: 18px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.add-product-instructions span {
  content: '';
  position: absolute;
  top: 0px;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50px;

  transform: translate(0px, 30%);
}

.add-product-instructions h4 {
  position: relative;
  font-size: 14px;
  color: white;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-left: 15px;
}

.add-product-instructions .view-ingredients {
  position: relative;
  color: white;
  text-align: center;
  font-size: 12px;
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.add-p-inst-textarea {
  flex: 1 1 0%;
  width: 100%;
  /* margin-top: 15px; */
  border-radius: 5px;
  outline: none;
  /* border: none; */
  /* padding: 20px; */
  margin-bottom: 20px;
  background-color: transparent;
  border: 1px solid white;
  min-height: 150px;
  /* margin: 10px 0px; */
  margin-top: 10px;
  color: white;
}

.modelvaraition .select-addons {
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 2px;
}

.modelvaraition .add-desert-head {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 13px;
  padding-top: 5px;
}

.add-deserts-box h5 {
  font-size: 16px;
  text-transform: uppercase;
}

.add-deserts-box p {
  font-size: 14px;
  margin-bottom: 5px;
  letter-spacing: 1px;
}

.add-to-cart-box .add-to-cart {
  background-color: white;
  color: black;
  border: 1px solid white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  font-weight: 700;
  justify-content: space-evenly;
  letter-spacing: 1px;
}

.add-to-cart-butn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loading-page {

  width: 100%;
  height: 100vh;
}

.loading-page .main-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 153px;
}

.main-box .logo-img {
  text-align: center;
  margin-bottom: 10px;
}

.main-box .logo-img img {
  width: 80%;
}

.loading-content {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
}

.loading-content-sscreen {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
}

.loading-content h6 {
  color: white;
  text-align: center;
  font-size: 28px;
  line-height: 35px;
}

.loading-content h6 span {
  color: #FF6F5B;
  display: block;
}

.loading-content-sscreen h6 span {
  color: #FF6F5B;
  display: block;
}

.loading-content-sscreen h6 {
  color: white;
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: -45px;

}

.search-message-content {
  color: white;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 30px;
  padding: 10px;
  letter-spacing: 2px;
}

.small-info {
  font-size: 12px;
  color: white;
  border-bottom: rgb(194, 194, 194) solid 1px;
  margin: 0px 10px;
  padding: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
}

.highlighter {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translate(0 px, 5px);
  margin-right: 8px;
  display: inline-block;
  margin-top: 7px;
}

.modelvaraition .s-variation-head {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.product-name-variations .heading {
  font-size: 18px;
  margin-bottom: 0px;
}

.price-section h4 {
  font-size: 16px;
  text-align: right;
}

.addons-desc {
  font-size: 11px;
  font-weight: 100 !important;
  letter-spacing: 1px;
  color: white;
  text-transform: uppercase;
}

.select-category-main-box {
  /* max-height: 100vh; */
  /* min-height: 70vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 15%; */
}

.d-f-s-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobileno-head {
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.mobile-reg-box {
  position: absolute;
  z-index: 9;
  text-align: center;
  top: 20%;
  left: 0;
  width: 90vw;
  right: 0;
  margin: 0 auto;
}

.mobile-registration-sec {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 170px;
  width: 100vw;
  max-width: 100%;
  min-height: 200px;
}

.reg-mobile-header {
  padding: 20px;
}

.reg-mobile-header .logo-img {
  text-align: center;
}

.overlay1 {

  background-color: rgba(0, 0, 0, 0.849) !important;
  position: absolute;
  left: 0px;
  min-height: 100%;
  width: 100%;
  top: 0;
}

.mobile-no-ip .input-group-text {
  color: white;
  background: transparent;
  font-size: 14px;
  letter-spacing: 1px;
  border-radius: 2px;
}

.mobile-no-ip input {
  background-color: transparent;
  border-radius: 2px;
}

.mobile-no-buttn {
  background-color: white;
  color: black;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
  border-radius: 4px;
  font-weight: 600;
  padding: 8px 25px;
  /* margin-top: 15px; */
}

.d-f-j-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-msg {
  color: white;
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 88%;
  margin: 0 auto;
}

.otp-bottom-info {
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
}

.otp-bottom-info a {
  color: #FF6F5B;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  padding: 10px;
}

.otp-head {
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 20px;
}

.otp-confirmation-sec {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.otp-confirmation-bg {
  margin-top: 150px;
  width: 100%;
  min-height: 235px;
  background-image: url('./assets/otp-confirmation-bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.otp-confirmation-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 90%;
  margin: 0 auto;
}

.confirm-buttn {
  background-color: #FF6F5B;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0px 10px; */
  min-height: 50px;
  border-radius: 5px;
  /* width: 50px; */
  color: white;
  font-size: 18px;
}

.otp-ip input {
  background-color: transparent;
  border-radius: 2px;
  min-height: 50px;

}

.otp-confirm-msg {
  color: white;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 88%;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  line-height: 25px;
}

video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
}

.product-price-area {
  margin-bottom: 15px;
}

.cart-product-name .main-head {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 100 !important;
  letter-spacing: 1px;
  color: #B1B1B1;
  max-width: 100%;
}

.selectaddons .modal-footer {
  display: flex;
  justify-content: space-between;
}

.prod-price-details span {
  padding: 10px;
  font-size: 14px;
}

.get-bill-main-box {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  text-align: center;
  top: 35%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.no-data-found {
  color: white;
  border-bottom: rgb(194, 194, 194) solid 1px;
  margin: 0px 10px;
  padding: 10px;
}

.select-addons-inr {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 100 !important;
}

.addon-item-name {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 100 !important;
  margin-bottom: 5px;
}

.modal-title h4 {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.selectadd-deserts {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.header-title .main-heading {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
}

.main-heading {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgb(0 0 0 / 30%) !important;
}

.no-product-name {
  text-align: center;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 200px;
}

.no-categories-found {
  color: white;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 100 !important;
  margin-top: 160px;
}

.search-heading {
  margin-top: 50px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  padding: 10px 0px;
}

.sub-total {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 300 !important;
}

.addons-price span {
  font-size: 12px;
  font-weight: 300 !important;
  color: #B1B1B1;
  padding-top: 20px;
}

.sub-total-head {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 300 !important;
  color: white;
  margin-bottom: 0px;
}

.grand-total-head {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 300 !important;
  color: #FF6F5B;
  margin-bottom: 0px;
}

.sub-total-price {
  font-size: 13px;
  color: #B1B1B1;
  margin-bottom: 0px;
  letter-spacing: 1px;
}

.grand-total-price {
  font-size: 13px;
  color: #FF6F5B;
  margin-bottom: 0px;
  letter-spacing: 1px;
  font-weight: 300 !important;
}

.grand-total-price1 {
  font-size: 13px;
  color: #FF6F5B;
  margin-bottom: 0px;
  letter-spacing: 1px;
}

.proceed-to-pay span {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 300 !important;
  letter-spacing: 1px;
}

.selectedVariant {
  border: solid 1px rgba(255, 255, 255, 0.6);
  border-radius: 6px;
  margin: 5px 0px;
}

.poweredBy {
  color: white;
  text-align: center;
}

.brder-bottm {
  border-bottom: 1px solid #6a6a6a;
  padding-bottom: 10px;
}

.proceed-to-pay-buttn {
  background-color: white;
  padding: 10px 15px;
  border-radius: 5px;
  border: transparent;
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.plus-icn {
  font-size: 14px;
  font-weight: 300 !important;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
}

.switch-toggle .toggle--checkbox {
  display: none;
}



.switch-toggle .toggle--label {
  width: 100px;
  height: 45px;
  background: #323232;
  border-radius: 100px;
  /* border: 1px solid #eceaea; */
  /* display: flex; */
  position: relative;
  transition: all 350ms ease-in;
}

.switch-toggle .toggle--checkbox:checked+.toggle--label {
  background: #efefef;
  border-color: #242424;
}

.switch-toggle {
  border-radius: 100px;
  position: relative;
}

.switch-toggle .toggle--label:before {
  animation-name: reverse;
  /* animation-duration: 350ms; */
  animation-fill-mode: forwards;
  transition: all 350ms ease-in;
  content: "";
  width: 25px;
  height: 25px;
  /* border: 5px solid #e8e8ea; */
  top: 2px;
  margin-left: 2px;
  /* left: 2px !important; */
  position: absolute;
  border-radius: 82px;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  color: #000;
  background-image: url("./assets/moon.svg");
}

.switch-toggle.themeloaded .toggle--label:before {
  animation-duration: 350ms;
}

.switch-toggle .toggle--checkbox:checked+.toggle--label:before {
  animation-name: switch;
  /* background: #fffaa8;
  border-color: #f5eb71; */
  background-image: url("./assets/sun.svg");
  background-color: #fff;
}

.switch-toggle .background {
  position: absolute;
  left: 0;
  top: 0;
  /* background: #c2e9f6; */
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: all 250ms ease-in;
}

/* .switch-toggle .toggle--checkbox:checked~.background {
    background: #808fc7;
} */

/* .switch-toggle .toggle--label:after {
  transition-delay: 350ms;
  transition: all 250ms ease-in;
  position: absolute;
  content: "";
  box-shadow: #c1c1c3 -13px 0 0 0px, #c1c1c3 -17px 7px 1px;
  left: 28px;
  top: 9px;
  width: 5px;
  height: 5px;
  background: transparent;
  border-radius: 50%;
  opacity: 1;
} */

.switch-toggle .toggle--checkbox:checked+.toggle--label:after {
  transition-delay: 0ms;
  opacity: 0;
}

.switch-toggle .toggle--checkbox:checked+.toggle--label .toggle--label-background {
  width: 11px;
  left: 25px;
}

.switch-toggle .toggle--label-background {
  height: 5px;
  border-radius: 5px;
  position: relative;
  background: #fff;
  top: 14px;
  transition: all 150ms ease-in;
}

.switch-toggle .toggle--checkbox:checked+.toggle--label .toggle--label-background:before {
  top: -4px;
  left: -13px;
}

.switch-toggle .toggle--checkbox:checked+.toggle--label .toggle--label-background:before,
.switch-toggle .toggle--checkbox:checked+.toggle--label .toggle--label-background:after {
  width: 25px;
  height: 3px;
}

.switch-toggle .toggle--checkbox:checked+.toggle--label .toggle--label-background:after {
  top: 6px;
  left: -5px;
}

.switch-toggle .toggle--label-background {
  left: 70px;
  width: 5px;
}

.switch-toggle .toggle--label-background:before {
  top: -7px;
  content: "";
  position: absolute;
  border-radius: 5px;
  background: #fff;
  left: -13px;
  transition: all 150ms ease-in;
}

.switch-toggle .toggle--label-background:before,
.switch-toggle .toggle--label-background:after {
  width: 5px;
  height: 5px;
}

.switch-toggle .toggle--label-background:after {
  left: -13px;
  top: 9px;
  content: "";
  position: absolute;
  border-radius: 5px;
  background: #fff;
  transition: all 150ms ease-in;
}



@media only screen and (max-width: 576px) {

  .switch-toggle .toggle--label:before {
    background-size: 70%;
    height: 20px;
    width: 20px;
  }

  .switch-toggle .toggle--label {
    height: 27px;
    width: 75px;
  }

  .switch-toggle .toggle--label-background {
    display: none;
  }

  .checktoggle p {
    margin-bottom: 0;
  }

  .blcktheme {
    position: absolute;
    top: 3px;
    color: #fff;
    right: 10px;
    font-size: 12px;
  }

  .whitetheme {
    position: absolute;
    top: 3px;
    color: #000;
    left: 12px;
    font-size: 12px;
  }

  .noneclass {
    display: none;
  }

  /* .toggle--label-background{
    position: relative;
} */
  /* .toggle--label-background{

position: absolute;
top: 0px;
right: 0;
content: 'light';
color: red;
font-size: 18px;
} */
  @keyframes switch {
    0% {
      left: 4px;
    }

    25% {
      left: 30px;
    }

    75% {
      left: 45px;
    }

    100% {
      left: 56px;
    }
  }

  @keyframes reverse {
    0% {
      left: 56px;
    }

    25% {
      left: 45px;
    }

    75% {
      left: 30px;
    }

    100% {
      left: 0px;
    }
  }

  .home_content__tICBz p {
    font-size: 18px !important;
  }
}

.imageToBlack {
  filter: brightness(0) saturate(0%);
}


.placeholderToBlack::-webkit-input-placeholder {
  color: #000
}

.select-category-bg {
  background-color: #000;
  min-height: 68vh;
}

.category-box-card {
  display: grid;
  place-items: center;
  margin-bottom: 20px;
}

.black-logo {
  text-align: center;
  margin-top: 20%;
}

.black-logo img {
  width: 75px;
}

.bottom_text .bottom-bar-text {
  font-size: 12px;
  text-align: center;
}

.color-black {
  color: black;
}

.color-white {
  color: white;
}

.cart-modal-box {
  inset: 50% auto auto 50%;
  border-radius: 10px;
  width: 80%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 15px;
  border-color: transparent;
}

.cart-modal-box .cart-modal-box-ip {
  border-radius: 10px;
  display: flex;
  flex: 1;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 10px;
  outline: none;
  padding: 10px;
  color: black;
}

.bg-ip-grey {
  background-color: #D9D9D9;
}

.bg-ip-white {
  background-color: white;
  border: 1px solid grey;
}

.cart-modal-box:focus-visible {
  outline: 0 !important;
}

.brdr-color-white {
  border: white;

}

.brdr-color-black {
  border-color: black;

}

.bg-greyish {
  background-color: #E6E6E6;
}

.bg-white {
  background-color: white;
}

.bg-blackk {
  background-color: #000;
}

.get-bill-main-box {
  background-color: transparent !important;
}

.d-f-j-c-e {
  display: flex;
  justify-content: end;
  align-items: center;
}

.ml-10 {
  margin-left: 10px;
}

.recomendation-product-card {
  display: flex;
  width: 100vw;
  padding: 10px;
  /* background-color: red; */
  flex-direction: column;
}

.recomendation-product-card img {
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0px 0px;
  /* display: block; */
}